import clsx from "clsx";
import { useCallback, useRef, useState } from "react";
import { useRouter } from "next/router";
import IconRightArrow from "@/common/icons/arrow-right.svg";
import IconSearch from "@/common/icons/search.svg";
import useLiveSearch from "@/common/hook/search/useLiveSearch";
import SearchBarLiveContainer from "@/components/common/SearchBar/SearchBarLiveContainer";
import { XIcon } from "@heroicons/react/outline";

const SearchBar = ({ isInHeader, isDetailPage, inputProps }) => {
  const router = useRouter();
  const [query, setQuery] = useState(isDetailPage ? router.query.q || "" : "");
  const { loading, data, search } = useLiveSearch(isDetailPage);
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const canUseBlur = useRef(true);
  const replaceURL = useCallback(
    (newQuery) => {
      setQuery(newQuery);
      const queries = { ...router.query };
      if (newQuery.length === 0) {
        delete queries.q;
      } else {
        queries.q = newQuery;
      }

      router.push(
        {
          pathname: isDetailPage ? router.pathname : "/search",
          query: queries,
        },
        undefined,
        { shallow: isDetailPage }
      );
      if (!isDetailPage) setDropdownOpen(false);
    },
    [isDetailPage, router]
  );

  const [cursor, setCursor] = useState(-1);

  return (
    <form
      className={clsx(
        "relative min-w-0 items-center gap-2 rounded-lg border border-grey-400 bg-white",
        isInHeader
          ? "hidden h-11 w-64 py-1.5 px-2.5 lg:flex lg:w-96 xl:w-[32rem]"
          : "flex h-full grow py-2.5 px-3.5 shadow-md md:p-6"
      )}
      onSubmit={(e) => {
        e.preventDefault();

        if (!isDetailPage) replaceURL(query);
      }}
    >
      <IconSearch
        className={clsx("h-5 w-5 shrink-0 text-grey-500", isInHeader ? "mr-2 md:h-4 md:w-4" : "mr-3 md:h-6 md:w-6")}
      />

      <input
        {...inputProps}
        aria-label="Search"
        type="text"
        className={clsx("h-7 w-full grow focus:outline-none", !isInHeader && "sm:text-xl md:h-9")}
        value={query}
        onChange={({ target }) => {
          const searchQuery = target.value;
          setCursor(-1);
          if (isDetailPage) {
            replaceURL(searchQuery);
          } else {
            setQuery(searchQuery);
          }
          if (searchQuery.length >= 2) search(searchQuery);
        }}
        onFocus={() => setDropdownOpen(true)}
        onBlur={() => setTimeout(() => setDropdownOpen(false), 100)}
        onKeyDown={(e) => {
          const length = data?.suggest?.length || 0;
          if (length > 0) {
            if (e.key === "ArrowDown") {
              setCursor((c) => (c < length ? c + 1 : c));
            }
            if (e.key === "ArrowUp") {
              setCursor((c) => (c > 0 ? c - 1 : 0));
            }
            if (e.key === "Enter" && cursor >= 0 && cursor < length) {
              e.preventDefault();
              replaceURL(data.suggest[cursor]);
            }
          }
        }}
      />
      <button
        type="button"
        className={clsx("button-icon h-8 w-8 shrink-0 rounded-full", query.length === 0 && "hidden")}
        onClick={() => {
          setCursor(-1);
          if (isDetailPage) {
            replaceURL("");
          } else {
            setQuery("");
          }
        }}
        aria-label="Clear search input"
      >
        <XIcon className="h-4 w-4" />
      </button>
      {!isDetailPage && (
        <button type="submit" className="button-icon h-8 w-8 shrink-0 rounded-full" aria-label="Search">
          <IconRightArrow className="h-4 w-4" />
        </button>
      )}

      <SearchBarLiveContainer
        cursor={cursor}
        loading={loading}
        data={data}
        isOpen={isDropdownOpen}
        closeRoutingOnBlur={() => {
          canUseBlur.current = false;
        }}
        search={(q) => replaceURL(q)}
        responsive={!isInHeader}
      />
    </form>
  );
};

export default SearchBar;
